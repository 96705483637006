import Api from "./Api";

export default class ReputationAPI extends Api {
	// Example:
	//  https://cabinet.chatkeeper.app/static/reputation/data-1001419771998.json
	static getFileUrl(id = "") {
		return `${ this.BASE_URL }/static/reputation/data${ id }.json`;
	}

}
