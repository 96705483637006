














































































































































































































































import axios from "axios";
import ReputationAPI from "@/includes/logic/ReputationAPI";
import { errorNotification } from '@/includes/NotificationService'
import UserMainInfo from "piramis-base-components/src/components/UserMainInfo.vue";
import TableUserActionButtons from '@/components/TableUserActionButtons.vue'

import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { UseFields } from "piramis-base-components/src/components/Pi"
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins, Ref } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  data() {
    return {
      moment,
    }
  },
  'components': {
    TableUserActionButtons,
    TableExportButtons,
    Accordion,
    Icon,
    UserMainInfo,
  },
})
export default class VuReputation extends Mixins(UseFields) {
  itemsPerPage: number = 10

  isMounted: boolean = false

  reputationList: Array<any> = []

  isRankSystemActive: boolean = false

  isXpSystemIsEnabled: boolean = false

  isReputationSystemIsEnabled: boolean = false

  search = ''

  tableTemplate = 0

  hintIsOpen = false

  gentime = ''

  columns = [
    {
      dataIndex: 'index',
      key: 'index',
      slots: { title: 'customIndex' },
      scopedSlots: { customRender: 'index' },
      width: 100
    },
    {
      key: 'name',
      slots: { title: 'userInfo' },
      scopedSlots: { customRender: 'userInfo' },
    },
    {
      dataIndex: 'karma',
      key: 'karma',
      slots: { title: 'customKarma' },
      sorter: (a:any, b:any) =>  a.karma - b.karma,
    },
    {
      dataIndex: 'anti_karma',
      key: 'anti_karma',
      slots: { title: 'customAntiKarma' },
      sorter: (a:any, b:any) =>  a.anti_karma - b.anti_karma,
    },
    {
      dataIndex: 'rank_title',
      key: 'rank_title',
      slots: { title: 'customRankTitle' },
      scopedSlots: { customRender: 'rank_title' },
    },
    {
      dataIndex: 'xp',
      key: 'xp',
      slots: { title: 'customXp' },
      scopedSlots: { customRender: 'xp' },
      sorter: (a:any, b:any) =>  a.xp - b.xp,
    },
    {
      dataIndex: 'action_point',
      key: 'action_point',
      slots: { title: 'customActionPoint' },
      scopedSlots: { customRender: 'action_points' },
      sorter: (a:any, b:any) =>  a.action_point - b.action_point,
    },
  ]

  get searchResult(): Array<any> {
    this.tableTemplate += 1
    if (!this.search.length) {
      return this.computedReputationList
    } else {
      return this.computedReputationList
        .filter(user => {
          if (user.name && user.name.toLowerCase().includes(this.search.trim().toLowerCase())) {
            return user
          }
          if (user.login && user.login.toLowerCase().includes(this.search.trim().toLowerCase())) {
            return user
          }
        })
    }
  }

  @Ref('table') readonly table!: any

  get currentPage(): number {
    if (this.isMounted) {
      return this.table.currentx
    }
    return 0
  }

  get computedReputationList(): Array<any> {
    return this.reputationList
  }

  set computedReputationList(value: Array<any>) {
    this.reputationList = value
  }

  get paginationSelectTitle(): string {
    return `${ this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1) } - ${ this.computedReputationList.length - this.currentPage * this.itemsPerPage > 0 ? this.currentPage * this.itemsPerPage : this.reputationList.length } ${ this.$t("of") } ${ this.computedReputationList.length }`
  }

  get exportTable(): Array<any> {
    return this.reputationList.map(user => {
      return {
        [this.$t('reputation_export_table_user_index').toString()]: user.index,
        [this.$t('reputation_export_table_user_id').toString()]: user.id,
        [this.$t('reputation_export_table_user_name').toString()]: user.name ? user.name : '-',
        [this.$t('reputation_export_table_user_login').toString()]: user.login ? user.login : '-',
        [this.$t('reputation_export_table_user_karma').toString()]: user.karma ? user.karma : '-',
        [this.$t('reputation_export_table_user_anti_karma').toString()]: user.anti_karma ? user.anti_karma : '-',
        [this.$t('reputation_export_table_user_rank_title').toString()]: user.rank_title ? user.rank_title : '-',
        [this.$t('reputation_export_table_user_rank_index').toString()]: user.rank_title ? this.getRankIndex(user.rank_title) : '-',
        [this.$t('reputation_export_table_user_xp').toString()]: user.xp ? user.xp : '-',
        [this.$t('reputation_export_table_user_action_points').toString()]: user.action_points ? user.action_points : '-',
      }
    })
  }

  getRankIndex(title: string): number {
    return this.$store.state.chatState.chat.config.user_ranks.findIndex((rank: { reputation: number, title: string, xp: number }) => rank.title === title) + 1
  }

  countDifference(currIndex: number, prevIndex: number): string | null {
    if (currIndex < prevIndex) {
      return `+${ prevIndex - currIndex }`
    }
    if (currIndex > prevIndex) {
      return `-${ currIndex - prevIndex }`
    }
    return null;
  }

  countDynamic(currIndex: number, prevIndex: number): string {
    if (currIndex < prevIndex) {
      return "up"
    }
    if (currIndex > prevIndex) {
      return "down"
    }
    return "hold"
  }

  getTooltipColor(direction: string): string {
    switch (direction) {
      case "up":
        return 'rgba(var(--a-success), 1)';
      case "hold":
        return 'rgba(var(--a-gray), 1)';
      case "down":
        return 'rgba(var(--a-danger), 1)';
      default:
        return 'rgba(var(--a-gray), 1)';
    }
  }

  async loadReputationData(): Promise<void> {
    const fileUrl = ReputationAPI.getFileUrl(this.$route.params[EntityTypes.CHAT_ID])

    try {
      this.$baseTemplate.loader.open();
      const { data } = await axios.get(fileUrl)

      this.computedReputationList = data.users
      this.gentime = data.gentime
      this.isRankSystemActive = data.user_ranks_by_xp || data.user_ranks_by_reputation
      this.isXpSystemIsEnabled = data.user_ranks_by_xp
      this.isReputationSystemIsEnabled = data.user_ranks_by_reputation
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  created(): void {
    this.$i18n.mergeLocaleMessage('ru', {
      'reputation_action_points': 'Очки действий',
      'reputation_next_rank_xp': 'Следующий ранг <b>«{0}»</b>. До него осталось  <b>{1}</b> очков опыта',
      'reputation_next_rank_reputation': 'Следующий ранг <b>«{0}»</b>. До него осталось  <b>{1}</b> очков репутации',
      'reputation_next_rank_xp_and_reputation': 'Следующий ранг <b>«{0}»</b>. До него осталось  <b>{1}</b> очков репутации и <b>{2}</b> очков опыта',
    })
    this.$i18n.mergeLocaleMessage('en', {
      'reputation_action_points': 'Action points',
      'reputation_next_rank_xp': 'The next rank is <b>«{0}»</b>. <b>{1}</b> experience points left before him',
      'reputation_next_rank_reputation': 'The next rank is <b>«{0}»</b>. <b>{1}</b> reputation points left before him',
      'reputation_next_rank_xp_and_reputation': 'The next rank is <b>«{0}»</b>. Before him there are <b>{1}</b> reputation points and <b>{2}</b> experience points',
    })
    this.loadReputationData()
  }

  mounted(): void {
    this.isMounted = true;
  }
}
