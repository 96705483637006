import { render, staticRenderFns } from "./TableUserActionButtons.vue?vue&type=template&id=2fe0d459&scoped=true&"
import script from "./TableUserActionButtons.vue?vue&type=script&lang=ts&"
export * from "./TableUserActionButtons.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe0d459",
  null
  
)

export default component.exports