









	// Project
import VuReputation from "@/components/chat/reputation/VuReputation.vue";
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'

import { Vue, Component } from 'vue-property-decorator'

@Component({
  'components': {
    VuReputation,
    CenteredColumnLayout,
    PageTitle
  },
})
export default class ReputationChart extends Vue {

}
